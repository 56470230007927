import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import { FooterAlternate1 } from "../../../components/footer"
import SEO from "../../../components/seo"

const IndexPage = ({location}) => (
    <Layout location={location}>
      <SEO title="I need to build my personal brand......" />
      
              <article className="article">
                  <header className="header header--primary article__header">
                      <div className="header__image">
                          <div className="header__bgimage" style={{backgroundImage:"url(/media/svg/landing-256x183_woman-laptop.svg)"}}></div>
                      </div>
                      <div className="header__content">
                          <div className="container">
                              <div className="row">
                                  <div className="header__inner col-sm-9 col-md-7 col-lg-6 col-lg-offset-1">
                                      <Link to="/knowledge-centre/challenges/" className="header__back back-link">
                                          <span className="sr-only">Back to all articles</span>
                                          <i className="icon"></i>
                                      </Link>
                                      <div className="header__title">
                                          <h3>Challenges</h3>
                                          <h1>I need to build my personal brand......</h1>
                                      </div>
                                      <h3 className="header__caption">Your personal brand is what people say about you when you are not in the room.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </header>
                  <div className="container" id="article-content">
                      <div className="row">
                          <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7">
                              <div className="rich-text"><p>According to Jim Keenan, <i>"for
              the first time in history, we get to shape how people see us before they meet
              us. By creating a
              strong, deliberate, social presence, we get to influence what people see and
              how they see us. We get to create the framework for how we want to be perceived
              by others".</i><br/></p><p>The future is for those sales professionals who can differentiate
              themselves with a personal brand that sets them apart from all of the other
              sales people out there, and who are able to convey a unique promise of value. All sales people must now work hard to build a strong network and 'personal brand' to future-proof yourself against decisions that are outside of your control. </p><p></p>

              <p>The future is for digitally savvy sales people who use all
              the channels they can to connect with their target customers, share unique
              insights and establish their thought leadership. They know that by remaining
              top-of-mind, when their customers are ready to buy, they will be the first one
              their customers call.</p><p>You must become: Digitally Driven, Socially Connected, Mobile and Specialized. </p><p>Register with SalesTribe to find out how!</p></div>
                          </div>
                          <aside className="article__sidebar col-sm-12 col-md-4 col-lg-offset-1 col-lg-3">
                              <div className="article__sidebar-wrap" id="article-sidebar">
                                  <h3>Are you ready to make a change?</h3>
                                  <div className="article__sidebar-btns">
                                    <a target="_self" href="https://community.salestribe.com/" className="btn btn-default">Join for Free!</a>
                                  </div>
                                  <nav className="social-nav">
                  <h4>Share</h4>
                  <ul>
                      <li className="linkedin">
                          <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/knowledge-centre/challenges/social-selling-and-my-online-brand/&amp;title=I%20need%20to%20build%20my%20personal%20brand......&amp;summary=I%20need%20to%20build%20my%20personal%20brand......&amp;source=https%3A//salestribe.com/knowledge-centre/challenges/social-selling-and-my-online-brand/" target="_blank" rel="noopener noreferrer">
                              <span className="sr-only">LinkedIn</span>
                              <span className="icon icon-linkedin"></span>
                              
                          </a>
                      </li>
                      <li className="twitter">
                          <a href="https://twitter.com/intent/tweet/?text=I%20need%20to%20build%20my%20personal%20brand......&amp;url=https%3A//salestribe.com/knowledge-centre/challenges/social-selling-and-my-online-brand/" target="_blank" rel="noopener noreferrer">
                              <span className="sr-only">Twitter</span>
                              <span className="icon icon-twitter"></span>
                              
                          </a>
                      </li>
                      <li className="googleplus">
                          <a href="https://plus.google.com/share?url=https%3A//salestribe.com/knowledge-centre/challenges/social-selling-and-my-online-brand/" target="_blank" rel="noopener noreferrer">
                              <span className="sr-only">Google+</span>
                              <span className="icon icon-google-plus"></span>
                              
                          </a>
                      </li>
                      <li className="facebook">
                          <a href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/knowledge-centre/challenges/social-selling-and-my-online-brand/" target="_blank" rel="noopener noreferrer">
                              <span className="sr-only">Facebook</span>
                              <span className="icon icon-facebook-f"></span>
                              
                          </a>
                      </li>
                      <li className="email">
                          <a href="mailto:?subject=I%20need%20to%20build%20my%20personal%20brand......&amp;body=https%3A//salestribe.com/knowledge-centre/challenges/social-selling-and-my-online-brand/" target="_blank" rel="noopener noreferrer">
                              <span className="sr-only">Email</span>
                              <span className="icon icon-envelope"></span>
                              
                          </a>
                      </li>
                  </ul>
              </nav>

                              </div>
                          </aside>
                      </div>
                  </div>
              </article>


              <div className="block block--base video" id="info-video">
                  <div className="video__wrap">
                      <div className="container">
                          <div className="row">
                              <div className="col-sm-12">
                                  <div className="video__image" data-video-uri="https://www.youtube.com/embed/1uGhNAGCTgc?autoplay=1&amp;modestbranding=0&amp;rel=0&amp;showinfo=0" data-video-target="#info-video .embed-responsive" data-video-parent="#info-video">
                                      <div className="video__bgimage" style={{backgroundImage:"url(/media/images/Mentor-Large-Cian-Mcloughlin.2e16d0ba.fill-1162x655.jpg)"}}></div>
                                      <div className="video__video">
                                          <div className="embed-responsive embed-responsive-16by9"></div>
                                      </div>
                                  </div>
                                  <div className="video__icon play-icon">
                                      <i className="icon"></i>
                                  </div>
                                  <div className="video__content">
                                      <div className="row">
                                          <div className="video__inner col-md-offset-2 col-md-8">
                                              
                                              <h1>A personal branding case study.</h1>
                                              <div className="video__caption">
                                                  <p className="h3">Why is &#39;personal branding&#39; so important?</p>
                                                  
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>


              <div className="block block--base quote">
                  <div className="container">
                      <div className="row">
                          <div className="quote__wrap col-sm-12">
                              <blockquote>
                                  <p>&quot;We are who the internet says we are&quot;.</p>
                                  <footer>Cian Mcloughlin</footer>
                              </blockquote>
                          </div>
                      </div>
                  </div>
              </div>

              {/* <div className="block block--base buttons"> */}
              {/*   <div className="container text-center"> */}
              {/*       <h3 className="buttons__title"><strong>Not sure where to start?</strong></h3> */}
              {/*       <Link to="/enquiries/" className="btn btn-default">Contact an advisor</Link> */}
              {/*   </div> */}
              {/* </div> */}
              <div className="block block--base block--ruledtop item-paging">
                  <div className="container">
                      <div className="row">
                          <Link to="/knowledge-centre/challenges/i-want-make-career-change/" className="item-paging__wrap col-sm-12">
                              <div className="row">
                                  <div className="item-paging__image col-sm-12 col-md-offset-1 col-md-5 col-lg-offset-2 col-lg-4">
                                      <img alt="SalesTribe" src="/media/svg/landing-256x183_man-pensive.svg" width="420" />
                                  </div>
                                  <div className="item-paging__content col-sm-12 col-md-4">
                                      <h3>Next challenge</h3>
                                      <h2 className="item-paging__title">I want to make a career change....</h2>
                                      <h3 className="item-paging__caption">Your career now depends on how well you understand the new realities of sales, and how well you leverage them to shape your future.</h3>
                                  </div>
                              </div>
                          </Link>
                      </div>
                  </div>
              </div>
      
    </Layout>
)

export default IndexPage 
